import {Component, OnInit} from '@angular/core';

import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import {TranslateService} from '@ngx-translate/core';

import {AgentCouponsService} from 'helio-games-core';

import {ScannedTicket} from './shared/models';
import {ScannedTicketComponent} from './scanned-ticket';
import {ErrorHandlerService, AuthService, AppOverlaysService, AppLayoutService} from '../shared/services';
import {AlertComponent} from '../alert';
import {ScanTicketComponent} from './scan-ticket';

@Component({
	selector: 'ra-check-ticket',
	templateUrl: './check-ticket.component.html',
	styleUrls: ['./check-ticket.component.scss']
})
export class CheckTicketComponent implements OnInit {
	couponID = '';
	cancellationInterval = 0;

	scanTicketEnabled = false;

	isCheckTicketLoading = false;
	isMobile = false;

	checkMobileTicketMenu = false;

	constructor(
		private couponsService: AgentCouponsService,
		private authService: AuthService,
		private appOverlaysService: AppOverlaysService,
		private appLayoutService: AppLayoutService,
		private errorHandlerService: ErrorHandlerService,
		private translateService: TranslateService
	) {
	}

	ngOnInit(): void {
		this.isMobile = this.appLayoutService.isMobile;
	}

	checkTicket(couponID: string) {
		couponID = couponID.replace(/\s/g, '');
		if (couponID !== '' && couponID !== undefined) {
			if (!this.isCheckTicketLoading) {
				this.isCheckTicketLoading = true;
				const autHeaderVal =
					`${this.authService.authTokenData.token_type} ${this.authService.authTokenData.access_token}`;

				this.couponsService.getCoupon(couponID, autHeaderVal).subscribe({
					next: agentCouponRes => {
						this.isCheckTicketLoading = false;
						if (agentCouponRes.isSuccess) {
							this.couponID = '';
							const scannedTicket = new ScannedTicket(agentCouponRes.coupon, agentCouponRes.cancellationIntervalSeconds);
							this.createScannedTicketOverlay(scannedTicket);
						} else {
							this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
								alertComponent.type = 'error';
								alertComponent.contentText = this.translateService.instant('general.errors.ticket-not-found');
							});
						}
					},
					error: error => {
						this.isCheckTicketLoading = false;
						this.errorHandlerService.handleError(error, this.translateService.instant('general.errors.ticket-not-found'));
					}
				});
			}
		}
	}

	scanTicket() {
		this.checkForCameraPermission();
	}

	toggleCheckTicketMenu = () => {
		this.checkMobileTicketMenu = !this.checkMobileTicketMenu;
	}

	private createScannedTicketOverlay(scannedTicket: ScannedTicket) {
		this.appOverlaysService.loadOverlay(ScannedTicketComponent, (componentInstance: ScannedTicketComponent) => {
			componentInstance.scannedTicket = scannedTicket;
		});
	}

	private checkForCameraPermission() {
		const tempScanner = new ZXingScannerComponent();

		tempScanner.askForPermission()
			.then((hasPermission) => {
				if (!hasPermission) {
					this.appOverlaysService.loadOverlay(AlertComponent, (componentInstance: AlertComponent) => {
						componentInstance.type = 'error';
						componentInstance.contentText = this.translateService.instant('general.errors.camera-permission');
					});
				} else {
					this.appOverlaysService.loadOverlay(ScanTicketComponent);
				}
			});
	}

}
